html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

@font-face {
	font-family: BCcardB;
	src: url('./fonts/BCcardB.otf') format("opentype");
}

@font-face {
	font-family: BCcardL;
	src: url('./fonts/BCcardL.otf') format("opentype");
}

@font-face {
	font-family: BMHANNA;
	src: url('./fonts/BMHANNAAir_otf.otf') format("opentype");
}

.home {
    width: 100%;
}

.sectionContainer .sectionContent {
    padding-bottom: 6rem;
}

.mainPageCenterContentContainer {
    text-align: center;
    padding-top: 35vh;
}

.mainPageCenterContentLabel {
    color: #aaa7a7;
    font-family: 'BMHANNA', sans-serif;
    font-size: 1.5em;
}

.mainPageCenterContentText {
    font-family: 'BCcardL', sans-serif;
    font-size: 1.5em;
    padding-top: 0.4em;
}

.textInWhite {
    color: white;
    display: inline-block;
}

.textInColor {
    color: #aaa7a7;
    display: inline-block;
    padding-left: .4em;
}

.welcomeButtonNoShow {
    display: none;
}

.welcomeContainer {
    text-align: center;
    width: 100%;
    bottom: 0;
    position: absolute;
    padding-bottom: 4em;
}

.welcomeButton {
    color: white;
    border: 1px solid white;
    border-radius: 15px;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 1.2em;
    width: 8em;
    height: 2.5em;
    line-height: 2.5em;
    margin-left: auto;
    margin-right: auto;
}

.aboutPageSpacer {
    height: 35vh;
}

.aboutPageCenterContentText {
    text-align: center;
    background-color: rgba(35,33,32,0.6);
    padding: 1.5em 0em;
}

.aboutText {
    display: inline-block;
    font-family: 'BCcardL', sans-serif;
    font-size: 1.3rem;
}

.aboutWhiteText {
    color: white;
}

.aboutColorText {
    color: #c4b3a3;
}

.aboutText1 {
    padding-right: .5em;
}

.aboutText2 {
    padding-right: .5em;
}

.aboutText3 {
    padding-right: .5em;
}

.aboutText42 {
    padding-right: .5em;
}

#homeSection {
    height: 100vh;
    background-image: url("./images/homeSectionCover.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#homeSection .sectionContent {
    padding-top: 80px;
}

#aboutSection {
    height: 80vh;
    background-image: url("./images/aboutSectionCover.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#aboutInfoSection {
    background-color: #dddddd;
    
}

.peopleSection {
    padding-top: 3em;
}

.sectionPadding {
    padding-top: 8em;
}

.labelBox {
    background-color: #656364;
    width: 20em;
}

.labelText {
    padding: .5em 0em;
    font-size: 1.5em;
    font-family: 'BCcardL', sans-serif;
    text-align: right;
    padding-right: 2em;
}

.lineSectionContent {
    padding-top: 2em;
}

.lineItem {
    font-family: 'BCcardL', sans-serif;
    color: black;
    border-left: 1px solid #656364;
    margin-left: 7em;
    padding-left: .7em;
    margin-top: 1.5em;
    font-size: 1.2em;
}

.address {
    border-left: none;
}

.callMe {
    display: inline-block;
}

.phoneNumber {
    padding-left: .5em;
    display: inline-block;
}

.googleMaps {
    margin-left: 9em;
    margin-top: 1.5em;
}

iframe {
    border-radius: 20px;
    border: 1px black solid;
}

@media screen and (max-width: 800px) {
    #homeSection {
        background-image: url("./images/homeSectionCoverSmaller.jpeg");
        background-attachment: scroll;
    }

    #aboutSection {
        background-image: url("./images/aboutSectionCoverSmaller.jpeg");
        background-attachment: scroll;
    }

    .labelBox {
        width: 13em;
    }

    .labelText {
        padding-right: 1.5em;
    }

    .lineItem {
        margin-left: 2em;
    }

    .googleMaps {
        margin-left: 3em;
    }
    
}

@media screen and (max-width: 450px) {
    .googleMaps {
        text-align: center;
        margin-left: 0em;
    }

    .address {
        margin-left: 0em;
        text-align: center;
        padding-left: 0em;
    }
}

@media screen and (max-width: 1000px) {
    .aboutPageSpacer {
        height: 25vh;
    }
    
    .blockText {
        display: block;
        text-align: left;
        padding-left: 10vw;
    }

    .aboutText2, .aboutText3, .aboutText4, .aboutText5 {
        padding-top: .6em;
    }
}

