@font-face {
	font-family: adobe-myungjo-std;
	src: url('../../fonts/adobe-std-m.otf') format("opentype");
}

.logo {
    width: 300px;
}

.logoSmaller {
	display: none;
}

.navColor {
	background: black;
	opacity: 0.75;
}

.navStick {
	display: none;
}

.navFixed {
	position: fixed;
}

.navTransparent {
	background: transparent;
}

.toggledMenuHidden {
	display: none;
}

.toggledMenu {
	display: none;
}

.youtubeDropdownItem {
	background-color: #8b0000;
	transition: 0.3s all ease;
}

.youtubeIconDropdown {
	color: red;
	opacity: 0.6;
	vertical-align: middle;
	padding-bottom: .25em;
	padding-left: 8px;
}

.liveDropdownLabel {
	display: inline-block;
}

@media screen and (max-width: 900px) {
	.toggledMenu {
		position: absolute;
		left: 0px;
		top: 100px;
		width: 100%;
		
		background: black;
		display: flex;
		flex-direction: column;
		transition: 0.3s all ease;
	}

	.logo {
		display: none;
	}

	.logoSmaller {
		display: block;
		width: 2em;
	}
	
}